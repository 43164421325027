<template>
  <div class="ProjectData">
    <h2>项目库</h2>
    <el-button type="primary" @click="handleAdd" :disabled="onCheckBtnAuthority('ProjectDataAdd')">添加</el-button>
    <div class="searchWrap">
      <el-row>
        <el-col :span="5">
          <div class="sb">
            <span>项目名称</span>
            <el-input
              class="searchInput"
              size="small"
              placeholder="请输入内容"
              v-model="projectName"
              clearable
            >
            </el-input>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="sb">
            <span>施工许可证号</span>
            <el-input
              class="searchInput"
              size="small"
              placeholder="请输入内容"
              v-model="builderLicenseNumber"
              clearable
            >
            </el-input>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="sb">
            <span>项目状态</span>
            <el-select
              v-model="projectStatus"
              clearable
              class="searchInput"
              size="small"
              placeholder="请选择"
            >
              <el-option
                v-for="item in projectStatusList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="sb">
            <span>项目分类</span>
            <el-select
              v-model="category"
              clearable
              class="searchInput"
              size="small"
              placeholder="请选择"
            >
              <el-option
                v-for="item in categoryList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" size="small" @click="onGetListData"
            >搜索</el-button
          >
        </el-col>
      </el-row>
    </div>
    <el-table :data="listData" style="margin-top: 20px">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="projectName" label="项目名称"> </el-table-column>
      <el-table-column prop="builderLicenseNumber" label="施工许可证号">
      </el-table-column>
      <el-table-column prop="projectStatusStr" label="项目状态">
      </el-table-column>
      <el-table-column prop="categoryStr" label="项目类型"> </el-table-column>
      <el-table-column prop="contractCorpName" label="总承包单位">
      </el-table-column>
      <el-table-column prop="buildNames" label="建设单位"> </el-table-column>
      <el-table-column prop="startDate" label="开工日期"> </el-table-column>
      <el-table-column align="right" width="270">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)" :disabled="onCheckBtnAuthority('ProjectDataEdit')"
            >项目信息</el-button
          >
          <el-button size="mini" @click="handleRequestChangeList(scope.row)" :disabled="onCheckBtnAuthority('ProjectDataLog')"
            >参建单位变更记录</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next"
      :total="totals"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onGetListData"
      @size-change="handleSizeChange"
      :page-sizes="[10, 50, 100, 200, 500]"
    >
    </el-pagination>

    <el-dialog
      title="项目信息"
      :visible.sync="showProjectBaseData"
      fullscreen
      :before-close="handleClose"
    >
      <el-form :model="projectBaseData" :rules="formRules" ref="ruleForm" label-width="140px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="项目名称" prop="projectName">
              <el-input
                clearable
                v-model="projectBaseData.projectName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="施工许可证号" prop="builderLicenseNumber">
              <el-input
                clearable
                v-model="projectBaseData.builderLicenseNumber"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="项目状态" prop="projectStatus">
              <el-select
                v-model="projectBaseData.projectStatus"
                clearable
                class="searchInput"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in projectStatusList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目分类" prop="category">
              <el-select
                v-model="projectBaseData.category"
                clearable
                class="searchInput"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in categoryList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="项目子类别" prop="categorySub">
              <el-input
                v-model="projectBaseData.categorySub"
                clearable
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="项目所在地" prop="areaAddress">
              <el-input
                v-if="projectBaseData.areaAddress"
                disabled
                v-model="projectBaseData.areaAddress"
              ></el-input>
              <el-cascader
                v-if="!projectBaseData.areaAddress"
                clearable
                ref="adress"
                :props="props"
                @change="handleChange"
                v-model="areaAddress"
                style="width: 100%"
              ></el-cascader> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="项目地址" prop="address">
              <el-input
                v-model="projectBaseData.address"
                clearable
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="总投资(万元)" prop="invest">
              <el-input
                v-model="projectBaseData.invest"
                clearable
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="总面积(平方米)" prop="buildingArea">
              <el-input
                v-model="projectBaseData.buildingArea"
                clearable
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="总长度(米)" prop="buildingLength">
              <el-input
                v-model="projectBaseData.buildingLength"
                clearable
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="开工日期" prop="startDate">
              <el-date-picker
                style="width: 100%"
                v-model="projectBaseData.startDate"
                value-format="yyyy-MM-dd hh:ss:mm"
                type="date"
                placeholder="选择日期"
                clearable
              >
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="竣工日期" prop="completeDate">
              <el-date-picker
                style="width: 100%"
                v-model="projectBaseData.completeDate"
                value-format="yyyy-MM-dd hh:ss:mm"
                type="date"
                placeholder="选择日期"
                clearable
              >
              </el-date-picker> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="项目简介" prop="description">
              <el-input
                v-model="projectBaseData.description"
                clearable
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="立项文号" prop="approvalNumber">
              <el-input
                v-model="projectBaseData.approvalNumber"
                clearable
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="甲方代表" prop="firstParty">
              <el-input
                v-model="projectBaseData.firstParty"
                clearable
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="甲方代表电话" prop="firstPartyPhone">
              <el-input
                v-model="projectBaseData.firstPartyPhone"
                clearable
              ></el-input> </el-form-item
          ></el-col>
          <!-- <el-col :span="12"
            ><el-form-item label="考勤模式" prop="isThirdparty">
              <el-radio-group v-model="projectBaseData.isThirdparty">
                <el-radio :label="0">人脸设备</el-radio>
                <el-radio :label="1">第三方对接</el-radio>
              </el-radio-group>
            </el-form-item></el-col
          > -->
        </el-row>
      </el-form>

      <h2>参建单位</h2>
      <el-table :data="projectCorpList" style="margin-top: 20px">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="corpTypeName" label="企业类型"></el-table-column>
        <el-table-column prop="corpName" label="单位名称"></el-table-column>
        <el-table-column
          prop="corpCode"
          label="统一社会信用代码"
        ></el-table-column>
        <el-table-column prop="legalMan" label="法人代表"></el-table-column>
        <el-table-column align="right" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="scope.$index != 0 || formType == 'Add'"
              size="mini"
              @click="handleDelCop(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-button
        style="width: 100%"
        icon="el-icon-circle-plus"
        @click="handleAddCop"
        >新增参建单位</el-button
      >

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>

    <el-drawer
      title="企业库"
      :visible.sync="showProjectCorpList"
      direction="ttb"
      size="80%"
    >
      <div
        class="searchWrap"
        style="background-color: #f9f9f9; margin: 0 0 20px 0"
      >
        <el-row>
          <el-col :span="5">
            <div class="sb">
              <span>统一社会信用代码</span>
              <el-input
                class="searchInput"
                size="small"
                placeholder="请输入内容"
                v-model="corpCode"
                clearable
              >
              </el-input>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="sb">
              <span>企业名称</span>
              <el-input
                class="searchInput"
                size="small"
                placeholder="请输入内容"
                v-model="corpName"
                clearable
              >
              </el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="sb">
              <span>企业登记注册类型</span>
              <el-select
                v-model="corpType"
                clearable
                class="searchInput"
                size="small"
                placeholder="请选择"
                :disabled="!projectCorpList.length"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="sb">
              <span>法定代表人姓名</span>
              <el-input
                class="searchInput"
                size="small"
                placeholder="请输入内容"
                v-model="legalMan"
                clearable
              >
              </el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <el-button type="primary" size="small" @click="onGetCopList"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>
      <el-table :data="copList">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="corpTypeName" label="企业类型"></el-table-column>
        <el-table-column prop="corpName" label="单位名称"></el-table-column>
        <el-table-column
          prop="corpCode"
          label="统一社会信用代码"
        ></el-table-column>
        <el-table-column prop="legalMan" label="法人代表"></el-table-column>
        <el-table-column align="right" width="100">
          <template slot-scope="scope">
            <el-button size="mini" @click="onAddCop(scope.row)">添加</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>

    <el-dialog
      title="参建单位变更记录"
      :visible.sync="showChangeList"
      width="70%"
    >
      <el-table :data="changeList" style="margin-top: 20px">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="remark" label="操作类型"> </el-table-column>
        <el-table-column prop="corpTypeStr" label="主体类型"> </el-table-column>
        <el-table-column prop="corpName" label="单位名称"> </el-table-column>
        <el-table-column prop="createTime" label="变更时间"> </el-table-column>
        <el-table-column prop="userName" label="操作人"> </el-table-column>
      </el-table>
      <el-pagination
        layout="total, prev, pager, next"
        :total="changeListTotals"
        :page-size="changeListPageSize"
        :current-page.sync="changeListPageNum"
        @current-change="handleRequestChangeList"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseChangeList">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
    
<style lang="less" scoped>
.ProjectData {
  padding: 20px;
}
</style>
    
<script>
export default {
  name: "ProjectData",
  components: {},
  data() {
    return {
      pageBtn: [],
      pageSize: 10,
      pageNum: 1,
      totals: 0,
      listData: [],
      projectName: "",
      builderLicenseNumber: "",
      projectStatus: "",
      category: "",
      projectStatusList: [],
      categoryList: [],

      formType: "",
      showProjectBaseData: false,
      areaAddress: null,
      props: {
        lazy: true,
        lazyLoad: this.lazyLoad,
      },
      projectBaseData: {
        projectId: null,
        projectName: null,
        builderLicenseNumber: null,
        projectStatus: null,
        category: null,
        categorySub: null,
        areaAddress: null,
        address: null,
        invest: null,
        buildingArea: null,
        buildingLength: null,
        startDate: null,
        completeDate: null,
        description: null,
        approvalNumber: null,
        firstParty: null,
        firstPartyPhone: null,
        lonLatSet: null,
        // isThirdparty: null,
      },
      formRules: {
        projectName: [{ required: true, message: "必填项", trigger: "blur" }],
        builderLicenseNumber: [{ required: true, message: "必填项", trigger: "blur" }],
        address: [{ required: true, message: "必填项", trigger: "blur" }],
        areaAddress: [{ required: true, message: "必填项", trigger: "blur" }],
        projectStatus: [{ required: true, message: "必填项", trigger: "blur" }],
        category: [{ required: true, message: "必填项", trigger: "blur" }],
        startDate: [{ required: true, message: "必填项", trigger: "blur" }],
        completeDate: [{ required: true, message: "必填项", trigger: "blur" }],
        buildingArea: [{ required: true, message: "必填项", trigger: "blur" }],
        invest: [{ required: true, message: "必填项", trigger: "blur" }],
        // isThirdparty: [{ required: true, message: "必填项", trigger: "blur" }]
      },
      projectCorpList: [],
      showProjectCorpList: false,
      corpCode: "",
      corpName: "",
      corpType: "",
      legalMan: "",
      copList: [],
      typeList: [],

      showChangeList: false,
      changeProjectId: null,
      changeList: [],
      changeListPageNum: 1,
      changeListPageSize: 10,
      changeListTotals: 0,
    };
  },
  computed: {},
  watch: {
    corpType: function () {
      this.onGetCopList();
    },
    projectStatus: function () {
      this.pageNum = 1
      this.onGetListData();
    },
    category: function () {
      this.pageNum = 1
      this.onGetListData();
    },
  },
  mounted() {
    this.onGetListData();
    this.onGetProjectStatusList();
    this.onGetCategoryList();
    this.onGetTypeList();
    this.onGetPageBtn()
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取当前页面按钮权限
    onGetPageBtn: function () {
      var that = this;
      var data = {
        id: "1685906967523463169",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/menu/buttons",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.pageBtn = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 检查按钮权限
    onCheckBtnAuthority: function (code) {
      var disable = true;
      if (this.pageBtn.length) {
        this.pageBtn[0].children.forEach((item) => {
          if (item.code == code) {
            disable = false;
          }
        });
      }
      return disable;
    },
    // 选择省市区
    handleChange: function (val) {
      var node = this.$refs["adress"].getCheckedNodes(false);
      var address =
        node[0].label + node[0].parent.label + node[0].parent.parent.label;
      this.projectBaseData.areaAddress = address;
    },
    // 省市区懒加载
    lazyLoad: function (node, resolve) {
      var code;
      if (node.hasOwnProperty("value")) {
        code = node.value;
      } else {
        code = "";
      }
      var that = this;
      var data = {
        code: code,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/region/select",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            const nodes = res.data.map((item) => ({
              value: item.code,
              label: item.name,
              leaf: node.level >= 2,
            }));
            resolve(nodes);
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取主列表数据
    onGetListData: function () {
      var that = this;
      var data = {
        status: 0,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        projectName: this.projectName,
        builderLicenseNumber: this.builderLicenseNumber,
        projectStatus: this.projectStatus,
        category: this.category,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/project/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.listData = res.data.dataList;
            that.totals = res.data.totals;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换分页大小
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.onGetListData();
    },
    // 获取项目状态字典
    onGetProjectStatusList: function () {
      var that = this;
      var data = {
        type: "projectStatus",
        className: "General",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/dictionary/common-interface",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.projectStatusList = res.data;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取项目分类字典
    onGetCategoryList: function () {
      var that = this;
      var data = {
        type: "projectType",
        className: "General",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/dictionary/common-interface",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.categoryList = res.data;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取企业类型字典数据
    onGetTypeList: function () {
      var that = this;
      var data = {
        type: "enterpriseType",
        className: "General",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/dictionary/common-interface",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.typeList = res.data;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 添加项目
    handleAdd: function () {
      this.projectBaseData.projectId = null;
      this.projectBaseData.projectName = null;
      this.projectBaseData.builderLicenseNumber = null;
      this.projectBaseData.projectStatus = null;
      this.projectBaseData.category = null;
      this.projectBaseData.categorySub = null;
      this.projectBaseData.areaAddress = null;
      this.projectBaseData.address = null;
      this.projectBaseData.invest = null;
      this.projectBaseData.buildingArea = null;
      this.projectBaseData.buildingLength = null;
      this.projectBaseData.startDate = null;
      this.projectBaseData.completeDate = null;
      this.projectBaseData.description = null;
      this.projectBaseData.approvalNumber = null;
      this.projectBaseData.firstParty = null;
      this.projectBaseData.firstPartyPhone = null;
      this.projectBaseData.lonLatSet = null;
      // this.projectBaseData.isThirdparty = 0;

      this.areaAddress = null;
      this.projectCorpList = [];
      this.showProjectBaseData = true;
      this.showProjectCorpList = false;

      this.formType = 'Add'
    },
    // 关闭
    handleClose: function () {
      this.projectBaseData.projectId = null;
      this.projectBaseData.projectName = null;
      this.projectBaseData.builderLicenseNumber = null;
      this.projectBaseData.projectStatus = null;
      this.projectBaseData.category = null;
      this.projectBaseData.categorySub = null;
      this.projectBaseData.areaAddress = null;
      this.projectBaseData.address = null;
      this.projectBaseData.invest = null;
      this.projectBaseData.buildingArea = null;
      this.projectBaseData.buildingLength = null;
      this.projectBaseData.startDate = null;
      this.projectBaseData.completeDate = null;
      this.projectBaseData.description = null;
      this.projectBaseData.approvalNumber = null;
      this.projectBaseData.firstParty = null;
      this.projectBaseData.firstPartyPhone = null;
      this.projectBaseData.lonLatSet = null;

      this.areaAddress = null;
      this.projectCorpList = [];
      this.showProjectBaseData = false;
      this.showProjectCorpList = false;
      this.$refs['ruleForm'].resetFields();

      this.formType = ''
    },
    // 获取企业库
    onGetCopList: function () {
      var that = this;
      if (!this.projectCorpList.length) {
        this.corpType = "9";
      }
      var data = {
        status: 1,
        corpCode: this.corpCode,
        corpName: this.corpName,
        corpType: this.corpType,
        legalMan: this.legalMan,
        pageNum: 1,
        pageSize: 0,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/enterprise/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.copList = res.data.dataList;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 编辑项目信息
    handleEdit: function (row) {
      this.formType = 'Edit' //设定为编辑模式
      var that = this;
      var data = {
        projectId: row.projectId,
        displayUnit: "true",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/project/details",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            console.log(res.data);
            res.data.projectCorpList.forEach((item) => {
              item.corpTypeName = item.corpTypeStr;
            });
            this.projectBaseData.projectId = res.data.projectId;
            this.projectBaseData.projectName = res.data.projectName;
            this.projectBaseData.builderLicenseNumber =
              res.data.builderLicenseNumber;
            this.projectBaseData.projectStatus = res.data.projectStatus;
            this.projectBaseData.category = res.data.category;
            this.projectBaseData.categorySub = res.data.categorySub;
            this.projectBaseData.areaAddress = res.data.areaAddress;
            this.projectBaseData.address = res.data.address;
            this.projectBaseData.invest = res.data.invest;
            this.projectBaseData.buildingArea = res.data.buildingArea;
            this.projectBaseData.buildingLength = res.data.buildingLength;
            this.projectBaseData.startDate = res.data.startDate;
            this.projectBaseData.completeDate = res.data.completeDate;
            this.projectBaseData.description = res.data.description;
            this.projectBaseData.approvalNumber = res.data.approvalNumber;
            this.projectBaseData.firstParty = res.data.firstParty;
            this.projectBaseData.firstPartyPhone = res.data.firstPartyPhone;
            this.projectBaseData.lonLatSet = res.data.lonLatSet;
            // this.projectBaseData.isThirdparty = res.data.isThirdparty;

            this.areaAddress = null;
            this.projectCorpList = res.data.projectCorpList;
            this.showProjectBaseData = true;
            this.showProjectCorpList = false;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 添加参建单位
    handleAddCop: function () {
      this.onGetCopList();
      this.showProjectCorpList = true;
    },
    // 删除参建单位
    handleDelCop: function (index) {
      this.$confirm("此操作将删除数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.projectCorpList.splice(index, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 插入参建单位数据
    onAddCop: function (row) {
      console.log(row);
      var checkCorp = false
      this.projectCorpList.forEach(item => {
        if (row.corpId == item.corpId) {
          checkCorp = true
        }
      })
      if (!checkCorp) {
        this.projectCorpList.push({
          projectCorpId: "",
          corpCode: row.corpCode,
          corpId: row.corpId,
          deptId: row.deptId,
          corpName: row.corpName,
          corpType: row.corpType,
          corpTypeName: row.corpTypeName,
          legalMan: row.legalMan,
          officePhone: row.officePhone,
        });
        this.showProjectCorpList = false;
        this.corpType = "";
      } else {
        this.$message({
          type: "info",
          message: "请勿重复添加参加单位",
        });
      }
    },
    // 确认提交
    handleConfirm: function () {
      this.$refs["ruleForm"].validate((valid) => {
        if (!this.projectCorpList.length) {
          this.$message('请添加参建单位');
          return
        }
        if (valid) {
          this.$confirm("此操作将提交数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.onSubmit();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    // 提交数据
    onSubmit: function () {
      var that = this;
      var data = this.projectBaseData;
      data.projectCorpList = this.projectCorpList;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/project/submit",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.$message({
              type: "success",
              message: "操作成功",
            });
            that.handleClose();
            that.onGetListData();
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 打开参建单位变更记录
    handleRequestChangeList: function (row) {
      var that = this;
      console.log("row", row);
      if (row.projectId) {
        this.changeProjectId = row.projectId;
      }
      var data = {
        projectId: this.changeProjectId,
        pageNum: this.changeListPageNum,
        pageSize: this.changeListPageSize,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/project/unitChangeRecord",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            console.log(res);
            that.changeList = res.data.dataList;
            that.changeListPageNum = res.data.pageNum;
            that.changeListPageSize = res.data.pageSize;
            that.changeListTotals = res.data.totals;
            that.showChangeList = true;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCloseChangeList: function () {
      this.changeProjectId = null;
      this.showChangeList = false;
      this.changeList = [];
      this.changeListPageNum = 1;
      this.changeListPageSize = 10;
      this.changeListTotals = 0;
    },
  },
};
</script>
    